import React from "react";

import {
  checkboxContainer,
  hiddenCheckbox,
  styledCheckbox,
  icon,
  show,
  hide,
} from "./Checkbox.module.scss";

const Checkbox = React.forwardRef(({ className, checked, ...props }, ref) => {
  return (
    <div className={`${checkboxContainer} ${className}`}>
      <input
        ref={ref}
        type="checkbox"
        checked={checked}
        className={hiddenCheckbox}
        {...props}
      />
      <div checked={checked} className={styledCheckbox}>
        <svg viewBox="0 0 24 24" className={`${icon} ${checked ? show : hide}`}>
          <polyline points="20 6 9 17 4 12" />
        </svg>
      </div>
    </div>
  );
});

export default Checkbox;
