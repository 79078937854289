import React from "react";
import { graphql, navigate } from "gatsby";
import parse from "html-react-parser";
import Layout from "../../components/Layout";
import Checkbox from "../../components/Checkbox/Checkbox";

import useSessionstorage from "@rooks/use-sessionstorage";
import { Button } from "../../components/Button/Button";
const gStyles = require("../../styles/richText.module.scss");
const styles = require("./ucits.module.scss");

const Disclaimer = ({
  location,
  data: {
    strapiUcitsDisclaimer: {
      mainCopy,
      checkboxText,
      heading,
      ctaLabel,
      metaData,
    },
  },
}) => {
  const [ucits, setUcits] = useSessionstorage("ucits", 0);

  const toUrl = location?.state ? location?.state?.toUrl : null;

  const handleCheckboxChange = (event) => {
    setUcits(event.target.checked === true ? 1 : 0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (ucits === 1) {
      navigate(toUrl);
    }
  };

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
      }}
    >
      <div className={gStyles.copyWrap}>
        <h1 className={`${gStyles.header} h2 `}>{heading}</h1>
        <div className={gStyles.content}>
          <div className={gStyles.textBlock}>
            {mainCopy ? parse(mainCopy) : null}

            <form onSubmit={handleSubmit} className={styles.disclaimerForm}>
              <div className={styles.checkBoxWrap}>
                <label>
                  <Checkbox
                    id="checkboxInput"
                    checked={ucits}
                    onChange={handleCheckboxChange}
                    className={styles.checkBox}
                  />
                  <span>{checkboxText}</span>
                </label>
              </div>
              <Button
                bkgColor="blackOnLight"
                label={ctaLabel}
                handleOnClick={handleSubmit}
                disabled={!ucits ? "disabled" : ""}
              />
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    strapiUcitsDisclaimer {
      mainCopy
      checkboxText
      heading
      ctaLabel

      metaData {
        pageTitle
        metadescription
      }
    }
  }
`;

export default Disclaimer;
